.App {
  height: 100%;
  width: 100%;
}

header {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  z-index: 60;
}


.hamburger-menu {
  position: fixed;
  color: #d1b76c;

  z-index: 999;
}

.hamburger-menu button {
  font-size: 1.5rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
}


