.side-menu {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 300px;
  background-color: #263147;
  box-shadow: 2px 0 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  -webkit-transition: transform 0.3s ease-in-out;
  -moz-transition: transform 0.3s ease-in-out;
  transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  z-index: 50; /* Lower z-index */
  
}
.side-menu-temp-btn {
  text-transform: none!important;
  min-width: 0px!important;
  color: #44b9c0!important;
  font-weight: 600!important;
}

.side-menu-btn {
  text-transform: none!important;
  min-width: 0px!important;
  color: #44b9c0!important;
}

.side-menu.open {
  transform: translateX(0);
  -ms-transform: translateX(0);
  -webkit-transform: translateX(0);
}
  
.side-menu nav {
  display: block;
  height: 100%;
}

.side-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.side-menu li {
  margin: 10px 0;
}

.side-menu a {
  text-decoration: none;
  font-size: 1.2rem;
  color: black;
}

.side-menu a:hover {
  color: gray;
}

.side-menu-txt {
  color: #d1b76c;
  background-color: transparent!important;
}

.btn-separator-clr {
  color: #d1b76c!important;
  text-transform: none!important;
  min-width: 0px!important;
  font-weight: 900!important;
}

.side-menu-btn-x {
  color: #d1b76c!important;
  text-transform: none!important;
  min-width: 0px!important;
  font-weight: 900!important;
}

.search-bar {
  background-color: #263147;
  border: 2px solid #d1b76c;
  color: #d1b76c;
}
